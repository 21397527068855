@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Cooper";
  src: url("../public/fonts/cooper_medium_bt.ttf");
}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.App {
  text-align: center;
  background-color: "#16191B";
}



*:focus {
  outline: var(--projcolor) auto 3px;
}

::-webkit-scrollbar {
  display: none;
}


/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow: hidden;
  background-color: "#16191B";

}

.header {
  font-family: 'Cooper';
  letter-spacing: 1.5px;

}



.subtitle {
  font-family: 'Inter';
  /* font-weight: medium; */
  letter-spacing: '1px';
}

.body-text {
  font-family: 'IBM Plex Mono';
}


a:hover {
  text-decoration: underline;
}

.nav:hover{
  display:flex;
  transform: translate(20px);
  /* border-bottom:2px solid white; */
}
.image-container {
  position: relative;
  display: inline-block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 192, 203, 0); /* Pink color with transparency */
  transition: background-color 0.3s ease; /* Transition effect */
  opacity: 0; /* Initially hidden */
}

.image-container:hover .overlay {
  background-color: #C2A7F7; /* Pink color with higher opacity on hover */
  opacity: 1; /* Show the overlay on hover */
}

.overlay p {
  color: #F8F8F2; /* Text color */
  text-align: center;
}



.custom-modal {
  transition: opacity 0.25s ease;
  max-height: "1005px";
  max-width: "5rem";
  padding:20px; 
  

}
.modal-wrapper{
  width:90%;
  height:100%;
}


@media screen and  (min-width:1024px) {
  .modal-wrapper{
    position:fixed;
    width: 50%;
    height:100%;
    margin: 20px auto;
  }
  
}
body { margin: 0; }

.ticker-wrap {
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  height: 3.5rem; 
}
.ticker {
  margin-top: 5px;
  animation: marquee 55s linear infinite;
}
.item-collection-1 {
  position: relative;
  left: 0%;
  animation: swap 55s linear infinite;
}

.traffic-replay-pattern{
  background-color: #16191b;
background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 10h10v10H0V10zM10 0h10v10H10V0z' fill='%23c2a7f7' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E");
}


.women-investors-pattern{
  background-color: #c2a7f7;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='112' height='92' viewBox='0 0 112 92'%3E%3Cg fill='%2316191b' fill-opacity='1'%3E%3Cpath fill-rule='evenodd' d='M72 10H40L16 20H0v8h16l24-14h32l24 14h16v-8H96L72 10zm0-8H40L16 4H0v8h16l24-6h32l24 6h16V4H96L72 2zm0 84H40l-24-6H0v8h16l24 2h32l24-2h16v-8H96l-24 6zm0-8H40L16 64H0v8h16l24 10h32l24-10h16v-8H96L72 78zm0-12H40L16 56H0v4h16l24 14h32l24-14h16v-4H96L72 66zm0-16H40l-24-2H0v4h16l24 6h32l24-6h16v-4H96l-24 2zm0-16H40l-24 6H0v4h16l24-2h32l24 2h16v-4H96l-24-6zm0-16H40L16 32H0v4h16l24-10h32l24 10h16v-4H96L72 18z'/%3E%3C/g%3E%3C/svg%3E");
}

.connect-jew-pattern{
  background-color: #16191b;
background-image: url("data:image/svg+xml,%3Csvg width='70' height='70' viewBox='0 0 70 70' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23c2a7f7' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 0h35v35H0V0zm5 5h25v25H5V5zm5 5h15v15H10V10zm5 5h5v5h-5v-5zM40 5h25v25H40V5zm5 5h15v15H45V10zm5 5h5v5h-5v-5zM70 35H35v35h35V35zm-5 5H40v25h25V40zm-5 5H45v15h15V45zm-5 5h-5v5h5v-5zM30 40H5v25h25V40zm-5 5H10v15h15V45zm-5 5h-5v5h5v-5z'/%3E%3C/g%3E%3C/svg%3E");
}

.tree-pattern{
  background-color: #c2a7f7;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='96' viewBox='0 0 60 96'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2316191b' fill-opacity='1'%3E%3Cpath d='M36 10a6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-12 0 6 6 0 0 0-6-6 6 6 0 0 1-6-6V10a6 6 0 1 1 12 0 6 6 0 0 0 12 0zm24 78a6 6 0 0 1-6-6 6 6 0 0 0-6-6 6 6 0 0 1-6-6V58a6 6 0 1 1 12 0 6 6 0 0 0 6 6v24zM0 88V64a6 6 0 0 0 6-6 6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-6 6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.item {
  display: inline-block;
  padding: 0 1rem;
}

/* Transition */
@keyframes marquee {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%)
  }
}

@keyframes swap {
  0%, 50% {
    left: 0%;
  }
  50.01%,
  100% {
    left: 100%;
  }
}
